@import "../design-tokens/master.scss";

.list {
	background: #e7dedf; 

	.section {
		display: flex;
		flex-flow: wrap;
		.section-main {
            padding:0em 1em;
			width: 100%;
			.letter-menu {
				padding: 0;
			}
			.main-wrapper {
				width: 100%;
				display: flex;
				flex-flow: wrap;
				.names-section {
					width: 70%;;
					@include respond-to(xs) {
						width: 100%;
					}
					@include respond-to(sm) {
						width: 100%;
					}
					@include respond-to(md) {
						width: 60%;
                    }
                    
                    
				}
				.adds-section {
					width: 30%;
					display: flex;
					flex-flow: wrap;
					@include respond-to(xs) {
						width: 100%;
					}
					@include respond-to(sm) {
						width: 100%;
					}
					@include respond-to(md) {
						width: 40%;
					}
					.aside-add{
						padding-left: 10% ;
					}
				}
			}
		}
	}
}

.similiar-name{
    padding-bottom: 0.5em;
}

button.goback-link {
    margin-top: 1em;
    text-decoration: none !important;
    padding: 0.4em !important ;
    border: 1px solid $colorNeutralLighter !important;
    font-weight: bold;
    color: #f45b69 !important;
    &:hover {
        color: white !important;
        background: #f45b69 !important;
    }                 
}
