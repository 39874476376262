@import "../design-tokens/master.scss";

.list {
	background: #e7dedf; 

	.section {
		display: flex;
		flex-flow: wrap;
		.section-title {
			padding:0em 1em;
			width: 100%;
		}
		.section-main {
			padding:0em 1em;
			width: 100%;
			.input-container{
				margin-bottom: 1em;
				input{
					height: 2em;
					border-radius: 0.5em;
					border:1px solid $colorNeutralLighter
				}
			}
			.letter-menu {
				padding-bottom: 1em;
			}
			.main-wrapper {
				width: 100%;
				display: flex;
				flex-flow: wrap;
				padding-top: 1em;
				.names-section {
					width: 70%;;
					@include respond-to(xs) {
						width: 100%;
					}
					@include respond-to(sm) {
						width: 100%;
					}
					@include respond-to(md) {
						width: 60%;
					}
					.filters-section {
						display: flex;
						flex-flow: wrap;
					}
				}
				.adds-section {
					width: 30%;
					display: flex;
					flex-flow: wrap;
					@include respond-to(xs) {
						width: 100%;
					}
					@include respond-to(sm) {
						width: 100%;
					}
					@include respond-to(md) {
						width: 40%;
					}
					.aside-add{
						padding-left: 10% ;
					}
				}
			}
		}
	}

	.pagination-section {
		.prev-link {
			padding:1em;
			float: left;
			a {
				text-decoration: none;
				padding: 0.4em;
				border: 1px solid $colorNeutralLighter;
				font-weight: bold;
				color: #f45b69;
				&:hover {
					color: white;
					background: #f45b69;
				}
			}
		}
		.next-link {
			padding:1em;
			float: right;
			a {
				text-decoration: none;
				padding: 0.4em;
				border: 1px solid $colorNeutralLighter;
				font-weight: bold;
				color: #f45b69;
				&:hover {
					color: white;
					background: #f45b69;
				}
			}
		}
	}
	.name-link{
		font-size: 1.1em;
		color: inherit;
		text-decoration: underline #f45b69;               
	}
}
