$colorPrimary: #5158bb;
$colorPrimaryLight: #7fdeff;
$colorPrimaryDark: #0a2463;

$colorSecondary: #ff69b4;
$colorSecondaryLight: #ffc3e1;
$colorSecondaryDark: #4c1f36;

$colorNeutralDarker: #212529;
$colorNeutralDark: #343a40;
$colorNeutral: #495057;
$colorNeutralLight: #6c757d;
$colorNeutralLighter: #adb5bd;
$colorNeutralLightest: #ced4da;
$colorWhite: #ffffff;

$spacer-xs: 4px;
$spacer-s: 8px;
$spacer-m: 12px;
$spacer-l: 17px;
$spacer-xl: 28px;   

$xl: 75em; // 1200px
$lg: 64.1em; // 1024px
$md: 48em; // 768px
$sm: 30em; // 480px
$xs: 20em; // 320px