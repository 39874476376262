@import '../design-tokens/master.scss';

.LetterNav{
    ul{
        padding: 0;
        margin: 0;
        list-style-type:none;   
        overflow: hidden;   
        li{
            float: left;                            
            a{
                padding: 0.4em 0.8em;
                display: block;
                margin: 0.1em;
                border: 1px solid $colorNeutralLighter;
                color: #f45b69;
                text-align: center;
                text-decoration: none;
                font-weight: 500;
                width: 1em;
                &:hover {
                    color: white;
                    background: #f45b69;
                }
                
            }
            a.Active {
                color: white;
                background: #f45b69;
            }
        }
    }     
}