@import '../design-tokens/master.scss';

.gender-menu {
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        overflow: hidden;
        li {
            float: left;
            a {
                padding: 0.2em 0.4em;
                display: block;
                margin: 0.1em;
                border: 1px solid $colorNeutralLighter;
                color: #f45b69;
                text-align: center;
                text-decoration: none;
                font-weight: 500;
                &:hover {
                    color: white;
                    background: #f45b69;
                }
            }
            a.active {
                color: white;
                background: #f45b69;
            }
        }
    }
}