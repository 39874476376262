
@mixin respond-to($media) {
  @if $media == xs {
    @media only screen and (max-width: $sm) { @content; }
  }
  @else if $media == sm {
    @media only screen and (min-width: $sm) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $md){ @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $lg) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $xl) { @content; }
  }
}


@function calc-em($target-px, $context) {
    @return ($target-px / $context) * 1em;
}
  



