@import '../design-tokens/master.scss';

.header {
    display: flex;
    flex-flow: wrap;
    background: #f45b69 !important;
    .header-logo {
        padding: 1.2em;
    }
    /* ==========================================================*/
    .header-menu {
        width:100%;
        background: #c23b46;
        padding-left: 1em;
        .menu {
            list-style: none;
            padding: 0;
            margin: 0;
            .menu-item {
                padding: 0.5em;
                float: left;
                overflow: hidden;
                color: #ffffff;
                .menu-link {
                    text-decoration: none;
                    color: inherit;
                }

                .sub-menu {
                    display: none;
                    position: absolute;
                    z-index: 9;
                }

                &:hover {
                    background: #ffffff;
                    color: Black;
                    .sub-menu {
                        list-style: none;
                        display: block;
                        background: white;
                        margin: 0;
                        padding: 0;                          
                        .sub-menu-item {
                            min-width: 6em;
                            padding: 0.5em;
                            color: black;
                            .sub-menu-link {
                                text-decoration: none;
                                color:inherit;
                            }
                            &:hover {
                                color: white;
                                background: #f45b69;
                            }
                        }
                    }
                }
            }
        }
    }
    /* ==========================================================*/
}