@import '../design-tokens/master.scss';

.footer {
    display: flex;
    flex-flow: wrap;
    background: #f45b69 !important;
    .footer-logo {
        padding: 1.2em;
    }
    .footer-add {
        // background: lightgrey;
        width: 100%;
    }
    .terms-and-conditions {
        // background: lightgreen;
        width: 100%;
        background: #c23b46;
        color: #ffffff;
        padding-left: 1em;
    }
}
