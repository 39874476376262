@import "../design-tokens/master.scss";

.home {
	background: #e7dedf; 

	.section {
		display: flex;
		flex-flow: wrap;
		.section-title {
			padding:0em 1em;
			width: 100%;
		}
		.section-main {
			padding:0em 1em;
			width: 100%;
			.history{
				padding-bottom: 1em;
			}
			.main-wrapper {
				width: 100%;
				display: flex;
				flex-flow: wrap;
				padding-top: 1em;
				.names-section {
					width: 70%;;
					@include respond-to(xs) {
						width: 100%;
					}
					@include respond-to(sm) {
						width: 100%;
					}
					@include respond-to(md) {
						width: 60%;
					}
                    ul {
                        list-style: none;
                        padding: 0em;
                        li{
                            list-style: none;
                            padding: 0.5em 0 0 1em;
                            a{
                                text-decoration: none;
                                font-weight: bold;
                                color: #f45b69;
                                &:hover {
                                    color: white;
                                    background: #f45b69;
                                }
                            }
                        }
                    }
				}
				.adds-section {
					width: 30%;
					display: flex;
					flex-flow: wrap;
					@include respond-to(xs) {
						width: 100%;
					}
					@include respond-to(sm) {
						width: 100%;
					}
					@include respond-to(md) {
						width: 40%;
					}
					.aside-add{
						padding-left: 10% ;
					}
				}
			}
		}
	}
}
