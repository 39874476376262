@import "variables.scss";
@import "mixin.scss";


.center-wrapper {
	@include respond-to(xs) {
		width: 100%;
	}
	@include respond-to(sm) {
		width: 100%;
	}
	@include respond-to(md) {
		width: 90%;
	}
	width: 80%;
	max-width: 1024px;
	margin: auto;
	background: #ffffff; 
}


.logo {
    display: block;
    font-size: 2.5em;
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 0;
    a{
        text-decoration: none;
        color:inherit;
    }
}
.logo-tag {
    display: block;
    font-size: 0.8em;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.panel{
    border: 1px solid grey;
    border-radius: 2%;
    .panel-header{
        padding: 0.2em;
        border-bottom: 1px solid grey;
        display: flex;
        justify-content: center;
    }
    .panel-header {
        padding: 0.2em;
        display: flex;
        justify-content: center;
    }
} 

table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
}

table td, table th {
    border: 1px solid #ddd;
    @include respond-to(xs) {  padding: 0.5em 0em 0.5em 0.2em; }
    @include respond-to(sm) {  padding: 0.5em 0em 0.5em 0.2em; }
    @include respond-to(md) {  padding: 0.5em 0em 0.5em 0.3em; }
    @include respond-to(lg) {  padding: 0.7em }   
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
    text-align: left;
    background-color: $colorNeutralLighter;
    color: white;
}